<template>
  <div>
    <font-awesome-icon icon="truck" class="mr-2" v-if="data.direct_delivery" />
    <strong>{{ getProducerName() }}</strong>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableName",

  methods: {
    getProducerName() {
      return get(this.data, "producer.role.name", "");
    },
  },
};
</script>
